.register-steps {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background: linear-gradient(180deg, #2c4f4f 0%, var(--main-color) 100%);
}

.register-steps h5 {
  font-size: 40px;
  line-height: 54px;
  font-weight: 600;
  padding: 30px 0;

  text-align: center;
  color: white;

}

/* .register-steps h5::after {
  content: "";
  display: block;
  width: 300px;
  height: 6px;
  background-color: var(--second-color);
  margin: 20px auto 10px auto;
} */

.transperant-bg {
  background-color: rgba(0, 0, 0, 0.801);
}

.register-steps h6 {
  color: white;
  font-size: 24px;
  line-height: 40px;
  font-weight: 300px;
  margin: 20px 0;
}

.step-icon {
  font-size: 35px;
  text-align: center;
  color: white;
  transition: all 0.5s ease;
}

.icon-div {
  
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
    transition: all 0.5s ease;
}

/* .icon-div:hover {
  background-color:var(--second-color);
} */


.col-steps {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

/* .col-steps::before{
        position: absolute;
    right: 75%;
    top: 20%;
    background: url(../../assests/dashed-arrow1.png) no-repeat 0 0;
    content: "";
    width: 174px;
    height: 29px;

} */

.diff::before {
  display: none;
}

@keyframes reg-rotaion {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@media screen and (max-width: 768px) {
  .icon-div {
    width: 60px;
    height: 60px;
  }
  /* .col-steps::before {
    position: absolute;
    right: 87%;
    top: 10%;
    background: url(../../assests/dashed-arrow1.png) no-repeat 0 0;
    content: "";
    width: 54px;
    height: 19px;
    animation: logoAn 1.5s infinite alternate;
  }
  @keyframes logoAn {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(15px);
    }
  } */
  .register-steps h6 {
    color: white;
    font-size: 18px;
    line-height: 40px;
    font-weight: 300px;
  }
}

@media screen and (max-width: 768px) {
  .register-steps h5 {
    font-size: 32px;
  }
}