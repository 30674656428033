.pagination-parent {
  padding: 40px 0;
}

.page-link {
  text-decoration: none;
  background-color: white !important;
  color: var(--second-color) !important;
}
.page-link:focus {
 color: white !important;
  background-color: var(--main-color) !important;
}
.page-link:hover {
  color: white !important;
  background-color: var(--main-color) !important;
}

.pagination{
   --bs-pagination-active-border-color: var(--main-color) !important;
   --bs-pagination-color: var(--main-color) !important;
}


.page-item:last-child .page-link {
    border-top-right-radius:0 !important;
    border-bottom-right-radius: 0 !important;
}

.page-item:first-child .page-link {
    border-top-left-radius:0 !important;
    border-bottom-left-radius: 0 !important;
}