.news-details h5{
    text-align: center;
    margin: 40px;
    font-weight: bold;
    font-size: 40px;
    color: var(--main-color);
}

.news-details h3{
    margin: 20px;
}

.news-details p{
    margin: 20px;
}