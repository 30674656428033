.services-header{
    /* background-image: url("../../assests/trading-4.jpg"); */
    width: 100%;
    height: 30vh;
    background-position: center;
    background-size: cover;
    position: relative;
     background: linear-gradient(180deg,#cce1e1 0%, var(--main-color) 100%);
}

.services-header .transperant-bg{
    background-color: rgba(0, 0, 0, 0.67);
    width: 100%;
    height: 30vh;
}

.services-header h5{
     background-color: #2c42513f;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 10px 0;
    text-align:center;
    color: white;
}

.services-header span{
    color: var(--main-color);
}

.services{
     background-color: #f7f7f7;

     margin: 0 ;
}

.services h4{
    margin: 0;
    padding: 40px 0;
}