.contact-comp{
         /* background-image: url("../../assests/bg-4.jpg"); */
  background-repeat: no-repeat;
  background-size: cover !important;
   background-attachment: fixed;
    background: linear-gradient(180deg, #2c4f4f 0%, var(--main-color) 100%);
}

.freelogin-sec textarea{
     margin: 12px;
  border: 1px solid var(--main-color);
  border-radius: 10px;
  padding: 10px 40px;
  outline: none;
  border: none;
  transition: all 0.5s ease;
}

.freelogin-sec textarea:focus{
  outline: none;
  box-shadow: 0 0 10px var(--main-color);
}

.contact-comp h6{
    color: white;
    font-size:35px;
    font-weight:700;
    margin: 20px 0;
}

.contact-comp h4{
   color: white;
    font-size:45px;
    font-weight: bold;
}

.problems{
background-color:#2c425196 !important;
}

.problems button{
      background-color: var(--second-color);
  padding: 10px;
  color: white;
 
  border-radius: 25px;
   border: 1px solid var(--second-color);
  transition: all 0.5s ease;
  font-weight: 600;
}

.problems button:hover{
 background-color: var(--second-color);
  border: 1px solid var(--main-color);

    color: white;
}

@media screen and (max-width: 768px){
  .contact-comp h4{
    font-size:38px;
    font-weight: 600;
}

.contact-comp h6{
    color: white;
    font-size:32px;
    font-weight:600;
}

}