.trust-info{
        /* background-image: url("../../assests/bg-7.jpg"); */
  background-repeat: no-repeat;
  background-size: cover !important;
   background-attachment: fixed;
  background: linear-gradient(180deg, #2c4f4f 0%, var(--main-color) 100%);
}


.trust-info h5{
    color: white;
}

.trust-info h6{
    color: var(--second-color) ;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}

.trust-info-icon{
    color: var(--second-color);
    margin: 0 20px;
    font-size: 50px;
}

@media screen and (max-width: 768px) {
    .info-trust-mob{
        margin: 15px 0;
    }
}