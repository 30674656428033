.hero-body {
  min-height: 223px;
  margin-top: 80px;
}

.btn-notfound {
  background: var(--main-color);
  padding: 10px;
  border-radius: 5px;
  color: black;
  font-weight: bold;
  transition: all 0.5s ease;
  text-decoration: none !important;
}

.btn-notfound:hover {
  background: #e4b51ad4;
  color: black;
}

.hero-body h1,
.hero-body h2 {
  margin: 40px 0;
}

.notfound {
  height: calc(100vh - (60.3px + 250.7px));
}
