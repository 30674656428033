.headbar h5 {
  font-weight: 500;
  margin: 15px 0;
  color: white;
  font-family: 'El Messiri', sans-serif;
  animation: logoAnim 2s infinite alternate;
}

@keyframes logoAnim {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(50px);
  }
}

.headbar span {
  margin: 0 10px;
}

.headbar {
 background: linear-gradient(180deg,#427878 0%, var(--main-color) 100%);
  
}

.headbar h6 {
  font-size: 16px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .headbar h6 {
  font-size: 12px;
  font-weight: bold;
}
@keyframes logoAnim {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(30px);
  }
}
}