:root {
  --main-color: #329292;
  --second-color: #2c4251;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  overflow-x: hidden !important;
}
.content-grid {
  display: grid;
  grid-template-columns: 176px auto;
}

.abc-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.App {
  font-family: "El Messiri", sans-serif;
}

.Toastify__toast--error,
.Toastify__toast--success {
  background: white !important;
  color: gray !important;
}

.Toastify__toast--error .Toastify__progress-bar {
  background-color: #e74c3c !important;
}

.Toastify__toast--success .Toastify__progress-bar {
  background-color: #07bc0c !important;
}

.Toastify__close-button > svg {
  color: gray !important;
}

.Toastify__close-button > svg:hover {
  color: black !important;
}
.Toastify__toast {
  margin-top: 15px;
  z-index: 999999;
}

@media screen and (max-width: 768px) {
  .container {
    width: 95% !important;
  }
  .Toastify__toast {
    width: 60%;
    margin-top: 35px;
  }
}

@media screen and (max-width: 992px) {
  .content-grid {
    display: block;
  }
}

.pagination {
  padding: 0 !important;
}
