.advantage-trust {
  /* background-image: url("../../assests/bg-9.jpg"); */
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center center;
  padding: 30px 0 60px 0;
  background-color: #f7f7f7;
}

.card {
  padding: 10px;
  min-height: 180px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  color: var(--main-color);
}

.card .advantage-icon {
  font-size: 40px !important;
  margin: 0 15px;
  color: var(--main-color);
}

.card .advantage-icon2 {
  font-size: 80px !important;
  margin: 0 15px;
  color: var(--main-color);
}

.card h6 {
  font-size: 20px !important ;
  font-weight: bold;
  padding: 10px 0;
}

.card p {
  color: var(--second-color);
}

.advantage-trust h4 {
  font-size: 40px;
  line-height: 54px;
  font-weight: 600;
  padding: 25px 0;
  text-align: center;
  margin: auto;
  color: var(--second-color);
}

/* .advantage-trust h4::after {
      content: "";
  display: block;
  width: 250px;
  height: 6px;
  background-color: var(--main-color);
  margin: 20px  auto 10px  auto;
} */

.private-text {
  color: var(--main-color);
  text-align: center;
  padding: 10px 0 30px 0;
  font-size: 18px;
}

.advantage-card {
  height: 90%;
}

.advantage-card {
  text-overflow: ellipsis;
  border-radius: 25px;
}

.advantage-card {
  position: relative !important;
  transition: all 0.5s ease;
  padding: 0 20px;
}

.advantage-card:hover {
  background-color: var(--main-color);
  transform: translateY(-1.1rem);
}

.advantage-card:hover p {
  color: white !important;
}

.advantage-card:hover h6 {
  color: white !important;
}

@media screen and (max-width: 768px) {
  .advantage-trust h4 {
    font-size: 32px;
  }
}
