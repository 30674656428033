.news-comp h4 {
  font-size: 45px;
  line-height: 54px;
  font-weight: 600;
  padding: 25px 0;
  text-align: center;
  margin: 0 auto 30px auto;
}

/* .news-comp h4::after {
  content: "";
  display: block;
  width: 200px;
  height: 6px;
  background-color: var(--main-color);
  margin: 20px auto 10px auto;
} */

.news-comp {
  padding-bottom: 30px;
}

.date-news {
  position: absolute;
  bottom: -16px;
  right: 0;
  background: var(--main-color);
  padding: 5px 10px;
  color: white !important;
   animation: newsAnim 5s infinite alternate;
}

.news-card {
  height: 472px;
  cursor: pointer;
}

.news-card .card-body {
  text-overflow: ellipsis;
  overflow: hidden;
}



@media screen and (max-width: 768px) {
  .news-card {
    width: 100%;
    height: 95%;
  }
}

.card-overlay {
  display: none;
 background-color: rgba(0, 0, 0, 0.589);
  transition: all 0.8s ease;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  border-radius: 5px;
}

.news-card:hover .card-overlay {
  display: block;
}

.news-card:hover .arrow-icon-div {
  display: block;
   display: flex;
  align-items: center;
  justify-content:center;
}

.arrow-icon {
  font-size: 50px;
 animation: arrowAnim 1s infinite alternate;
}

.arrow-icon-div {
  background-color: var(--main-color);
  width: 100px;
  height: 60px;
  border-radius: 10px;
  top: 55%;
  right: 35%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content:center;
}

.news-comp button{
  background-color: var(--main-color);
  color: white;
  padding: 8px 25px;
  border: 1px solid var(--main-color);
  border-radius: 25px;
  transition:all 0.5s ease;
  margin: 12px 0;
}

.news-comp button:hover{
  background-color: var(--second-color);
 
}

@keyframes arrowAnim {

  to {
    transform: translateX(5px);
  }
}

@media screen and (max-width: 768px) {
  .news-comp h4 {
    font-size: 32px;
  }
}