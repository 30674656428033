
.training-header{
     background-image: url("../../assests/trading-4.png");
  width: 100%;
  height: 30vh;
  background-position: center;
  background-size: cover;
  position: relative;
}

.training-header .transperant-bg {
  background-color: rgba(0, 0, 0, 0.67);
  width: 100%;
  height: 30vh;
}

.training-header h5 {
  background-color: #2c42513f;
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0;
  padding: 10px 0;
  text-align: center;
  color: white;
}

.training-header span {
  color: var(--main-color);
}

.done-course {
  color: green;
  margin: 0 10px;
  font-size: 18px;
}
.training {
  background-color: #f7f7f7;
}

.training-card {
  margin: 30px auto;
  
}

.training-card h5 {
  margin: 20px 0 30px 0;
  color: var(--main-color);
}

.training-card h4 {
  color: white;
}

.training-card p {
  color: var(--second-color);
}

.training-card button {
  background-color: transparent;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  padding: 8px 25px;
  border-radius: 5px;
  margin: 5px 0;
  transition: all 0.5s ease;
}

.training-card button:hover {
  background-color: var(--main-color);
  color: white;
  border: 1px solid var(--main-color);
 
}

.training h3{
    font-size: 40px;
  line-height: 54px;
  font-weight: 600;
  padding: 25px 0;
  text-align: center;
  margin: auto auto 30px auto;
  color: var(--second-color);
}

/* .training h3::after{
    content: "";
  display: block;
  width: 210px;
  height: 4px;
  background-color: var(--second-color);
  margin: 10px  auto 10px  auto;
} */


@media screen and (max-width: 768px){
    /* .training .container{
        width: 80% !important;
        margin: auto;
    } */

    .training-card h4 {
  font-size: 16px;
}

.training-card p {
   font-size: 14px;
}

}