.services {
  background-color: #f7f7f7;
  /* margin: 20px 0 0 0; */
  padding-bottom: 60px;
  display: flex;
}

.services h4 {
  font-size: 40px;
  line-height: 54px;
  font-weight: 600;
  padding: 25px 0;
  text-align: center;
  margin: 10px auto 30px auto;
    color: var(--second-color);
}

/* .services h4::after {
  content: "";
  display: block;
  width: 170px;
  height: 6px;
  background-color: var(--main-color);
  margin: 20px auto 30px auto;

} */

.services-icon2 {
  font-size: 120px !important;
  margin: 0 15px;
  color: var(--main-color);
}

.services-icon {
  font-size: 80px !important;
  margin: 0 15px;
  color: var(--main-color);
}

.services-icon3 {
  font-size: 60px !important;
  margin: 0 15px;
  color: var(--main-color);
}

.loading {
  padding: 30px 0;
}

.services-card {
  height: 90%;
   border-radius: 25px ;
   border: none;
}

.services-card {
  position: relative !important;
  transition:all 0.5s ease;
}

.services-card:hover {
 background-color: var(--main-color);
transform: translateY(-1.1rem) ;
 
}

.services-card:hover p {

 color: white !important;
}

.services-card:hover h6 {

 color: white !important;
}


.advantage-icon ,.advantage-icon2{
  position: absolute !important;
  right: 37%;
  border: 3px solid rgba(219, 217, 217, 0.67);
  padding: 20px;
  width: 80px !important;
  height: 80px !important;
  border-radius: 50%;
  top: -40px;
  background-color:white;
}

.card{
  box-shadow: 0 0 1px var(--main-color);
}

@media screen and (max-width: 768px) {
  .services h4 {
    font-size: 32px;
  }
}