.copy-right{
background-color: var(--main-color);
color: black;
height: 8vh;
display: flex;
justify-content:center;
align-items:center;

}

.copy-right h6{
    font-weight: bold;
}