.your-issue-header{
     background-image: url("../../assests/trading-4.png");
    width: 100%;
    height: 30vh;
    background-position: center;
    background-size: cover;
    position: relative;
}

.your-issue-header .transperant-bg{
    background-color: rgba(0, 0, 0, 0.67);
    width: 100%;
    height: 30vh;
}

.your-issue-header h5{
     background-color: #2c42513f;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 10px 0;
    text-align:center;
    color: white;
}

.your-issue-header span{
    color: var(--main-color);
}

.your-issue{
     background-color: #f7f7f7;
}


.your-issue h4 , .your-issue h6{
    
font-size:25px;
    font-weight: 600;
    color: black;
}

.contact-info h4 {
font-size:50px;
    font-weight: bold;
    color: var(--main-color);
}

.contact-info h6{
font-size:35px;
    font-weight: bold;
    margin: 0 0 25px 0;
     color: var(--main-color);
}