.news-header{
    /* background-image: url("../../assests/trading-4.jpg"); */
    width: 100%;
    height: 30vh;
    background-position: center;
    background-size: cover;
    position: relative;
     background: linear-gradient(180deg,#cce1e1 0%, var(--main-color) 100%);
}
.news-header .transperant-bg{
    background-color: rgba(0, 0, 0, 0.67);
    width: 100%;
    height: 30vh;
}

.news-header h5{
    background-color: #2c42513f;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 10px 0;
    text-align:center;
    color: white;
}

.news-header span{
    color: var(--main-color);
}

.news{
     background-color: #f7f7f7;
     margin: auto;
     padding: auto;
}

.news-content h4{
    font-size: 45px;
  line-height: 54px;
  font-weight: 600;
  padding:30px 0 25px 0;
  text-align: center;
  margin: 0 auto 20px auto;
  color: var(--second-color);
}

/* .news-content h4::after{
    content: "";
  display: block;
  width: 160px;
  height: 6px;
  background-color: var(--main-color);
  margin: 20px  auto 10px  auto;
} */

.news-content select{
    border-radius: 25px;
    padding: 5px;
    text-align: center;
}

.row-news button{
  background-color: var(--main-color);
  color: white;
  padding: 8px 30px !important;
  border: 1px solid var(--main-color);
  border-radius: 25px !important;
  transition:all 0.5s ease;
  margin: 12px 0 !important;
}

.row-news button:hover{
  background-color: var(--second-color);
 
}


.row-news{
    min-height: 60vh;
}

.news-content  select{
    width: 100%;
    cursor: pointer;
   border: 1px solid var(--main-color);
   outline:none;
}

select.decorated option:hover{
 box-shadow: 0 0 10px 100px #1882A8 inset !important;
 background-color: red;
}


.news-content  select:focus{
   box-shadow: 0 0 10px var(--main-color);
   border: 1px solid var(--main-color);
}

.news-content button{
    background-color: var(--main-color);
    border: 1px solid var(--main-color);
    padding: 10px 60px;
    border-radius: 5px;
}

.no-news{
text-align: center;
font-size: 32px;
font-weight: bold;
color: var(--main-color);
}

@media screen and (max-width: 768px){
    .news-content select{
        margin:10px 0;
    }
      .news-content button{
        margin:10px 0;
    }
}


@media screen and (max-width: 768px) {
  .news-content h4 {
    font-size: 38px;
  }
}