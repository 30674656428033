.icons-trust-footer {
  display: flex;
  flex-direction: column;
}

.footer-links {
  text-decoration: none;
  color: var(--second-color);
  transition: all 0.5s ease;
  text-align: center;
  font-size: 18px;

  width: fit-content;
  margin: 8px auto !important;
  font-weight: bold;
  color: white;
}

.footer-links:hover {
  color: var(--main-color);
  transform: scale(1.1);
}

.footer {
  background-color: var(--second-color);
  padding: 40px 0;
}

.footer h5 {
  border: 2px inset #3c596c;
  color: white;
  width: 56%;
  padding: 15px 0;
  margin: auto auto 40px auto !important;
  text-align: center;
  margin: auto;
  font-size: 22px;
 
  border-radius: 25px;
}

.footer h3 {
  border: 2px inset #3c596c;
  width: 40%;
  padding: 15px 0;
  margin: auto auto 30px auto;
  text-align: center;
  font-size: 22px;

  border-radius: 25px;
  color: white;
}

.footer img {
 height: 80px;
  margin: 0 0 30px 0;
}

.footer-img {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.footer .information-icons {
  margin: 0 10px;
  font-size: 25px;
  color: white;
}

.information-icons {
  margin: 0 10px;
  font-size: 25px;
}

.information-icons2 {
  margin: 0 10px;
  font-size: 20px;
}

.footer .information-icons2 {
  margin: 0 10px;
  font-size: 20px;
  color: white;
}

.footer p {
  font-size: 16px;
  height: 172px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
     line-height: 25px;
}

.footer h6 {
font-size: 16px;
  margin: 0 !important;
  color: white;
}

.social-icon {
  color: white;
  font-size: 20px;
  
}

.social-icon-parent {
  border: 2px solid var(--main-color);
  border-radius: 50%;
  margin: 0 15px 0 0;
  padding: 8px 10px;
  cursor: pointer;
  transition: all 0.5s ease;
}
.social-icon-parent:hover {
 background-color: var(--main-color)
}
