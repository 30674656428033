.side-bar {
 background-color: var(--second-color);
  width: 100%;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 65px;
  align-items: center;
  height: 100vh;
  padding: 50px 0 0 0;
  border-left: 1px solid var(--main-color);
   border-bottom: 1px solid var(--main-color);
}

.side-bar .links-div {
  margin: 8px auto;
  padding: 10px 0;
  width: 90%;
  text-align: center;
  border-radius: 5px;
  transition: all 0.5s ease;
}

.side-bar .links-div:hover {
  margin: 8px auto;
  background-color: var(--main-color);
  padding: 10px 0;
  width: 90%;
  text-align: center;
  border-radius: 5px;
}

.side-bar .active-link {
  margin: 8px auto;
  background-color: var(--main-color);
  padding: 10px 0;
  width: 90%;
  text-align: center;
  border-radius: 5px;
}

.side-bar .links {
  color: white;
  text-decoration: none;
}

.side-bar .links:hover {
  color: white;
  text-decoration: none;
  border: none;
}


@media screen and (max-width: 992px) {
.side-bar{
  display: none;
}

}