.about-header{
    /* background-image: url("../../assests/trading-4.jpg"); */
    width: 100%;
    height: 30vh;
    background-position: center;
    background-size: cover;
    position: relative;
     background: linear-gradient(180deg,#cce1e1 0%, var(--main-color) 100%);
}

.about-header .transperant-bg{
    background-color: rgba(0, 0, 0, 0.67);
    width: 100%;
    height: 30vh;
}

.about-header h5{
    background-color: #2c42513f;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 10px 0;
    text-align:center;
    color: white;
}

.about-header span{
    color: var(--main-color);
}

.container-body{
    min-height: 60vh;
}

.about{
     background-color: #f7f7f7;
}

.back-home{
    text-decoration: none;
    color: white ;
    transition: all 0.5s ease;
}

.back-home:hover{
   color: var(--main-color) ;
}