.performance-details-header {
  background-image: url("../../assests/trading-4.png");
  width: 100%;
  height: 30vh;
  background-position: center;
  background-size: cover;
  position: relative;
}

.performance-details-header .transperant-bg {
  background-color: rgba(0, 0, 0, 0.67);
  width: 100%;
  height: 30vh;
}

.performance-details canvas{
  width: 250px !important;
  height: 250px !important;
}

.performance-details .pie-chart{

  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
}

.performance-details-header h5 {
  background-color: #2c42513f;
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0;
  padding: 10px 0;
  text-align: center;
  color: white;
}

.performance-details-header span {
  color: var(--main-color);
}

.performance-details {
  background-color: #f7f7f7;
  padding: 30px 0;
}

.performance-details select {
  border-radius: 25px;
  padding: 5px;
  text-align: center;
}

.performance-details button {
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  padding: 10px 60px;
  border-radius: 5px;
}

.performance-details h4 {
  margin: 20px 0;
}

.performance-details h2 {
  font-size: 30px;
}

.performance-details h3 {
  font-size: 20px;
}

.result-recommendation span {
  color: var(--main-color);
   margin: 0 5px;
}

.perf-card-card {
  width: 90%;
  margin: 20px auto 80px auto;
}

.details-card-table thead {
  background-color: var(--main-color);
}

.details-card-table {
  background-color: white;
}

.performance-details select {
  width: 100%;
  cursor: pointer;
  border: 1px solid var(--main-color);
  outline: none;
}

select.decorated option:hover {
  box-shadow: 0 0 10px 100px #1882a8 inset !important;
  background-color: red;
}

.performance-details select:focus {
  box-shadow: 0 0 10px var(--main-color);
  border: 1px solid var(--main-color);
}

.result-recommendation {
  margin: 40px 0 60px 0;
}

.result-recommendation h2 {
  font-weight: bold;
  font-size: 20px;
  color: var(--main-color);
  margin: 20px 0;
}

.result-recommendation h3 {
  font-size: 16px;
  margin: 10px;
}

.no-total {
  white-space: nowrap;
  margin-top: 20px;
  margin-right: 20px;
  font-size: 25px;
  font-weight: bold;
  color: var(--main-color) !important;
}

.done-recom {
  background-color: rgb(76, 187, 119) !important;
color: white;
}


@media screen and (max-width: 768px) {
  .performance-details select {
    margin: 10px 0;
  }
  .performance-details button {
    margin: 10px 0;
  }
}
