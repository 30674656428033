.free-login {
  /* background-image: url("../../assests/bg.png"); */
  background-repeat: no-repeat;
  background-size: cover !important;
  padding-bottom: 40px;
  padding-top: 60px;
   background-color: white;
}

.freelogin-sec {
  box-shadow: 0 0 5px var(--main-color);
}

.freelogin-sec h5 {
  padding: 30px;
  background-color: var(--second-color);
  margin: 0;
  text-align: center;
  font-weight: bold;
  color: white;
}

.freelogin-sec .freelogin-inputs {
  padding: 16px;
  background-color: rgba(225, 223, 223, 0.988);
  display: flex;
  flex-direction: column;
}

.freelogin-sec h6 {
  padding: 16px;
}

.freelogin-sec input {
  margin: 12px;
  border: 1px solid var(--main-color);
   border-radius: 25px;
  padding: 10px 40px;
  outline: none;
  border: none;
  transition: all 0.5s ease;
}

.freelogin-sec input:focus {

  box-shadow: 0 0 10px var(--main-color);
}

.freelogin-sec button {
  padding: 10px 8px;
  margin: 10px auto;
  width: 25%;
  background-color: var(--second-color);
  border: 1px solid var(--second-color);
  color: white !important;
  border-radius: 5px;
  transition: all 0.5s ease;
   border-radius: 25px;
}

.freelogin-sec button:hover {
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  color: var(--second-color);
  
}

.react-tel-input .selected-flag {
  width: 48px !important;
}

.react-tel-input .selected-flag .flag {
  margin-right: 22px;
}

.special-label {
  display: none;
}

.react-tel-input .form-control {
  border-radius: 40px;
  padding: 20px 50px;
  border: 1px solid #d8d8d8;
  color: black;
  outline: none;
  font-weight: 600;
  width: 94% !important;
  border: none;
  outline: none;
}

.react-tel-input {
  margin-right: 12px;
  margin: 12px;
  transition: all 0.5s ease;
}

.free-login h4 {
 font-size: 35px;
  line-height: 54px;
  font-weight: 600;
  padding: 25px 0;
  width: 100%;
  text-align: center;
  border-radius: 10PX;
  color: var(--second-color);
}

.free-login .text1 {
  color: var(--main-color);
  padding: 10px 0;
  text-align: center;
}

.free-login .text2 {
  color: var(--second-color);
  padding: 10px 0;
  text-align: center;
}

.btn-most{
  background-color: var(--main-color);
  padding: 12px 25px;
  color: white;
  border: 1px solid var(--main-color);
  border-radius: 25px;
  transition: all 0.5s ease;
  text-decoration: none;
 
}

.btn-most:hover {
  background-color: var(--second-color);
  border: 1px solid var(--second-color);
  color: white;
}


.freelogin-sec select{
  width: 100%;
      padding: 5px 40px;
       border-radius: 25px;
       outline: none;
       cursor: pointer;
}

.countdown {
  text-align: center;
  /* margin-bottom: 20px !important; */
  border: 2px outset rgba(230, 227, 227, 0.3) !important;
  border-radius: 10px 10px 0 0 !important;
  padding: 10px;
  background-color: var(--second-color);
  min-height: 99px;
}

.countdown span {
  color: white;
  font-size: 50px !important;
}


@media screen and (max-width: 768px){
    .freelogin-sec{
        margin-bottom:60px;
    }
    .trust-sec{
        margin-bottom:60px;
    }

    .free-login h4{
      width:100%
    }
    .free-login img{
      height: 320px !important;
    }
}