.carosel h3 {
  font-weight: bold;
  font-size: 2.5rem;
}
.carosel p {
  font-size: 20px;
  line-height: 24px;
}

.slide-text {
  background-color: #00000070;
  border-radius: 10px;
  bottom: 38%;
}

.carosel img {
  width: 100%;
  height: 600px;
}

.carosel {
  min-height: 600px;
}

.loading-carsoul {
  min-height: 600px;
}

.carosel .carousel-control-next,
.carosel .carousel-control-prev {
  display: none;
}

@media screen and (max-width: 768px) {
  .slide-text {
    display: none;
  }

  .carosel {
    height: 300px !important;
    min-height: 300px;
    margin: 0 !important;
  }

  .carosel img {
    width: 100%;
    height: 320px !important;
  }
}
