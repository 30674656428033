.packages-header {
  /* background-image: url("../../assests/trading-4.jpg"); */
  width: 100%;
  height: 30vh;
  background-position: center;
  background-size: cover;
  position: relative;
  background: linear-gradient(180deg, #cce1e1 0%, var(--main-color) 100%);
}

.packages-header .transperant-bg {
  background-color: rgba(0, 0, 0, 0.67);
  width: 100%;
  height: 30vh;
}

.packages-header h5 {
  background-color: #2c42513f;
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0;
  padding: 10px 0;
  text-align: center;
  color: white;
}

.packages-header span {
  color: var(--main-color);
}

.packages {
  background-color: #f7f7f7;
}

.packages h6 {
  margin: 10px 0;
}

.packages button {
  margin: 25px 0;
  background-color: white;
  border: 1px solid var(--main-color);

  padding: 8px 35px;
  border-radius: 25px;
  color: black;
  transition: all 0.5s ease;
}

.packages button:hover {
  background-color: var(--second-color);
  border: 1px solid var(--second-color);

  color: white;
}

.package-icon {
  font-size: 65px;
  border-radius: 50%;
  padding: 10px;
  background-color: var(--main-color);
  border: 2px solid white;
}

.packages-card {
  transition: all 0.5s ease;
  margin: 60px 0;
  cursor: pointer;
  /* background-color: var(--second-color);
  color: var(--main-color); */
  border: none;
}

.packages-card:hover {
  transform: scale(1.1);
}

/* .packages-card:hover {
  border: 2px solid var(--main-color);
  color: black;
} */

/* .packages-card:hover button{
  background-color: var(--main-color);
  color: black;
   border: 1px solid var(--main-color);
} */

.packages-card:hover .package-icon {
  animation: reg-rotaion 2s infinite linear;
  background-color: var(--main-color);
  border: 2px dashed white;
}

.package-icon::after {
  content: "";
  display: block;
  width: 160px;
  height: 6px;
  background-color: var(--main-color);
  margin: 20px auto 10px auto;
}

.package-content h4 {
  font-size: 45px;
  line-height: 54px;
  font-weight: 600;
  padding: 30px 0 25px 0;
  text-align: center;
  margin: 0 auto 0 auto;
  color: var(--second-color);
}

/* .package-content h4::after {
  content: "";
  display: block;
  width: 150px;
  height: 6px;
  background-color: var(--main-color);
  margin: 20px auto 0 auto;
} */

.containerr {
  text-align: center;
  background: #ddd;
}

.rounded {
  margin: 0 auto;
  border-radius: 50px !important;
  width: 300px;
  height: 100px;
  background: var(--main-color);
  padding: 10px;
  border: 10px solid #ddd;
  z-index: 1;
  position: relative;
}

.digged {
  margin: 0 auto;
  background: var(--main-color);
  width: 400px;
  height: 500px;
  margin-top: -40px;
  margin-bottom: 40px;
}

.packages-card h5 {
  color: var(--main-color);
  font-weight: bold;
}

.packages-card h6 {
  padding: 20px;
  font-weight: normal;
  color: #f7f7f7;
}

.packages-card h3 {
  padding: 30px 0;
  font-weight: 600;
  color: #f7f7f7;
}

.icon-package {
  font-size: 40px;
  margin-left: 10px;
}

.head-transp {
  color: white;
  font-family: "El Messiri", sans-serif;
  font-size: 30px;
  font-weight: bold;
  position: relative;
  top: 30%;
}

.package-content h4 {
  font-size: 38px;
}

@media screen and (max-width: 768px) {
  .packages-card:hover {
    transform: none;
  }
}
