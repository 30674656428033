.modal-parent{
    z-index: 99999;
}
.modal-header .btn-close{
    margin: 0!important;
}

.modal-inputs input{
  margin: 15px;
  border: 1px solid var(--main-color);
  border-radius: 25px;
  padding: 10px 40px;
  outline: none;
  border: none;
  transition: all 0.5s ease;
}

.modal-inputs input:focus {
  outline: none;
  box-shadow: 0 0 10px var(--main-color);
}

.modal-inputs button ,.register-inputs button{
      padding: 10px 8px;
  margin: 20px auto;
  width: 35%;
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  color: white;
  border-radius: 25px;
  transition: all 0.5s ease;
font-weight: bold;
white-space: nowrap;
}

.modal-inputs button:hover ,.register-inputs button:hover{
     background-color: #30c2c1;
  border: 1px solid #30c2c1;
  
}

.register-inputs input ,.register-inputs select{
     margin: 15px 0 ;
  border: 1px solid var(--main-color);
  border-radius: 25px;
  padding: 10px 40px;
  outline: none;
  border: none;
  transition: all 0.5s ease;
  width: 100%;
}

.register-inputs input:focus ,.register-inputs select:focus {
  outline: none;
  box-shadow: 0 0 10px var(--main-color);
}

.register-inputs select{
    cursor: pointer;
}

.modal-content{
   font-family: 'El Messiri', sans-serif;
}