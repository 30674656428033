.question-details {
  min-height: 80vh;
  background-color: #f7f7f7;
}

.question-details h1 {
  margin: 20px 0;
  text-align: right !important;
}

.card-comp {
  align-items: stretch !important;
}

.question-details h5 {
  margin: 50px 0;
  text-align: right !important;
}

.question-details .bg-light {
  width: 100%;
}

.answers {
  margin: 20px 0;
  border: 1px solid #ccc;
  padding: 10px;
  background: #f7f7f7;
  border-radius: 10px;
}

.answer-card {
  width: 100%;
}

.question-details h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
  color: #1e2833;
}

.answers h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #1e2833;
  opacity: 0.6;
}

.question-details .btn {
  background-color: var(--main-color);
  color: white;
  transition: all 0.5s ease;
  padding: 8px 12px;
}

.comment-date p {
  margin: 0;
  font-size: 12px;
  color: gray;
  white-space: nowrap;
}

.question-details .btn-outline-primary {
  background-color: gray;
}

.question-details .btn-outline-primary {
  background-color: gray;
}

@media screen and (max-width: 768px) {
  .mob-question {
    flex-direction: column-reverse;
  }
}
